<template>
    <div />
</template>
<script>

import { getAuthorize } from '@/api/AvifyAuthorize'

export default {
  name: 'Authorize',
  created () {
    const token = this.$store.state.auth.token
    const email = this.$store.getters.loggedUser.email
    const query = this.$route.query

    getAuthorize(token, email, query)
      .then(res => {
        window.location = res
      })
      .catch(err => {
        window.location = this.processError(err, query)
      })
  },
  methods: {
    processError (err, query) {
      let redirect = '/'
      if (query.redirect_uri) {
        redirect = query.redirect_uri
      }
      return redirect + '?error=' + err.error + '&message=' + err.message
    }
  }
}
</script>
