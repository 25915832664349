import axios from '../services'

const URL_AUTHORIZE = '/sso/authorize'

export function getAuthorize (token, email, query) {
  query.jwt = token
  query.email = email
  return axios.get(URL_AUTHORIZE, {
    params: query
  }).then(res => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error.response.data)
  })
}
